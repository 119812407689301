import fetch from "@/utils/fetch";
import {CreateDictMedicalCare, UpdateDictMedicalCare} from "@/@type/dict-medicalcare";

export default class DictMedicalCareApi {

  async create(data : CreateDictMedicalCare) {
    return await fetch.post("/api/dict/medical-care", data)
  }

  async update(data: UpdateDictMedicalCare) {
    return await fetch.put("/api/dict/medical-care", data)
  }

  async getList() {
    return await fetch.get("/api/dict/medical-care")
  }

  async delete(uid: string) {
    return await fetch.delete("/api/dict/medical-care/"+uid)
  }

  async restore(uid: string) {
    return await fetch.put("/api/dict/medical-care/"+uid+"", null)
  }
}
