enum tokens {
    ACCESSES_SERVICE = "ACCESSES_SERVICE",
    OFFER_SERVICE = "OFFER_SERVICE",
    METRIC_SERVICE = "METRIC_SERVICE",
    ORGANIZATION_SERVICE = "ORGANIZATION_SERVICE",
    EMPLOYEE_SERVICE = "EMPLOYEE_SERVICE",
    POSITION_SERVICE = "POSITION_SERVICE",
    PERSON_SERVICE = "PERSON_SERVICE",
    ACCOUNT_SERVICE = "ACCOUNT_SERVICE",
    DIRECTION_SERVICE = "DIRECTION_SERVICE",
    DIRECTION_ITEM_SERVICE = "DIRECTION_ITEM_SERVICE",
    CASH_SERVICE = "CASH_SERVICE",
    CASH_SETTINGS_SERVICE = "CASH_SETTINGS_SERVICE",
    CASH_USER_SERVICE = "CASH_USER_SERVICE",
    CASH_SHIFT_SERVICE = "CASH_SHIFT_SERVICE",
    SRC_SERVICE = "SRC_SERVICE",
    SHIFT_SERVICE = "SHIFT_SERVICE",
    RECEIPT_SERVICE = "RECEIPT_SERVICE",
    NAVIGATION_SERVICE = "NAVIGATION_SERVICE",
    REPORT_SERVICE = "REPORT_SERVICE",
    REPORT_SERVICE_ROW = "REPORT_SERVICE_ROW",
    REPORT_SERVICE_ROW_COLUMN = "REPORT_SERVICE_ROW_COLUMN",
    REPORT_SERVICE_COLUMN = "REPORT_SERVICE_COLUMN",
    MEDICAL_CARE_SERVICE = "MEDICAL_CARE_SERVICE",
}

export default tokens
