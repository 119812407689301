export class DictMedicalCare {
  uid: string;
  title: string;
  deleted: boolean;
}

export class CreateDictMedicalCare {
  title: string;
}

export class UpdateDictMedicalCare {
  uid: string;
  title: string;
}
