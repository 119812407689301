import DictMedicalCareApi from "@/service/api/dict/dict-medicalcare.api";
import {CreateDictMedicalCare, DictMedicalCare, UpdateDictMedicalCare} from "@/@type/dict-medicalcare";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";

const api = new DictMedicalCareApi()

export default interface DictMedicalCareService {
  create(data : CreateDictMedicalCare): Promise<string>;
  update(data : UpdateDictMedicalCare): Promise<string>;
  getList(): Promise<DictMedicalCare[]>;
  delete(uid: string): Promise<boolean>;
  restore(uid: string): Promise<boolean>;
}

@Service(tokens.MEDICAL_CARE_SERVICE)
export default class DictMedicalCareServiceImpl implements DictMedicalCareService {
  async create(data: CreateDictMedicalCare): Promise<string> {
    return await api.create(data);
  }

  async delete(uid: string): Promise<boolean> {
    return await api.delete(uid);
  }

  async getList(): Promise<DictMedicalCare[]> {
    return await api.getList();
  }

  async restore(uid: string): Promise<boolean> {
    return await api.restore(uid);
  }

  async update(data: UpdateDictMedicalCare): Promise<string> {
    return await api.update(data);
  }

}
